import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TopImage from "../components/topImage"
import { RenderAst } from "../components/styledComponents"

export default function HomeTopImageDetails({ data }) {
  const { htmlAst, topImage, frontmatter } = data.markdownRemark
  const image = topImage.childImageSharp.gatsbyImageData
  return (
    <Layout>
      <Seo />
      <TopImage topImage={image} title={frontmatter.title} />
      <div className="container mx-auto flex flex-wrap justify-center w-9/12 pt-10">
        {RenderAst(htmlAst)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeTopImageDetails($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
      }
      topImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: COVER, cropFocus: CENTER }
            placeholder: BLURRED
          )
        }
      }
    }
  }
`
